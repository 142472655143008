<template>
  <BaseEventForm ref="form" :isEdit="true"/>
</template>

<script>
import BaseEventForm from '../../Form/Base';

export default {
  name: 'SettingEventEdit',
  components: {
    BaseEventForm
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
  }
};
</script>
